.container {
    /* margin: 1rem; */
    text-align: left;
}

.nav {
    background-color: rgb(64, 64, 64);
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
}

.site-title {
    font-size: 2rem;
}

.nav ul {
    padding: o;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.nav li.active {
    background-color: #555;
}

.nav li:hover {
    background-color: #777;
}

.homelogo {
    width: 50px;
    height: 50px;
}
