.App {
  text-align: center;
}

body {
  margin: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ), url("https://imgs.search.brave.com/4W2h-zcaf37UTxA6YDHMQlocLiiOv9XXOjeNFqOxHIs/rs:fit:1200:768:1/g:ce/aHR0cHM6Ly9pMi53/cC5jb20vd3d3Lmdh/bWVyZm9jdXMuY28v/d3AtY29udGVudC91/cGxvYWRzLzIwMTkv/MDYvcG9rZW1vbl9j/b2xsYWdlLmpwZz9m/aXQ9MTMzNiUyQzc2/OCZzc2w9MQ"); 
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  background-color: #8b0000 ;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 253, 253);
}

.App-pokedex {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ), url("https://imgs.search.brave.com/d8IMsmz4WQkK7IFwTQZ4b28m_ZGduc8kp0G5UFGj9ys/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly93YWxs/cGFwZXJjYXZlLmNv/bS93cC93cDMxNzAx/NzMucG5n");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  background-color: #8b0000 ;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 253, 253);
}

.App-TypePokemon {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ), url("https://imgs.search.brave.com/-PrSTWQuS3Abqz61z89ChmwhdCB6F7Nmn7qZ4BYskp8/rs:fit:1200:1080:1/g:ce/aHR0cHM6Ly90d2lu/ZmluaXRlLm5ldC93/cC1jb250ZW50L3Vw/bG9hZHMvMjAxOS8w/OS9Qb2tlbW9uVHlw/ZXMucG5n");
  /* background-repeat: no-repeat;
  background-size: cover; */
  min-height: 100vh;
  background-color: #363636 ;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 253, 253);
}

/* .App-link {
  color: #acacac;
} */

.App-color-table {
  color:rgb(182, 181, 181) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
